header{
    position: fixed;
    width: 100%;
    transition: all 0.2s ease-out;
    z-index: 10;
}

.c-header{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.c-header>div:first-child{
    display:flex;
    align-items: center;
    gap: 4rem;
}

.c-header__logo{
    height: 5rem;
    transition: all 0.2s ease-out;
}

.c-header__nav ul{
    display: flex;
    list-style: none;
}

.c-header__nav ul li{
    margin-right: 4rem;
    font-weight: 400;
    font-size: 1.05rem;
    display:flex;
    align-items: center;
}

.c-header__nav ul li a,
.c-header__nav ul li span{
    white-space: nowrap;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease-out;
}

.c-header__nav ul li span{
    display:flex;
    align-items: center;
    gap: 0.5rem;
}

.c-header__nav ul li a:hover,
.c-header__nav ul li span:hover{
    background-color: var(--light-green);
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.c-header__nav-li-dropdown{
    position: relative;
}

.c-header__nav-li-open span{
    background-color: var(--light-green);
}

.u-header-home .c-header__nav-li-open span{
    background-color: rgb(49, 53, 90);
}

.u-header-scrolled.u-header-home .c-header__nav-li-open span{
    background-color: var(--light-green);
}

/*
SERVICES
*/

.c-header__services{
    border: 1px solid var(--gray);
    display:none;
    position: absolute;
    top: 120%;
    background-color: var(--white);
    padding: 1rem;
    border-radius: 0.5rem;
}

.c-header__service{
    display:flex;
    padding: 0.5rem;
    align-items: center;
    gap: 1rem;
}

a.c-header__service:hover,
.u-header-home a.c-header__service:hover{
    background-color: var(--light-green);
}

.c-header__service-title{
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color:var(--black);
}

.c-header__service-subtitle{
    font-size: 0.75rem;
    color: #979797;
    margin: 0;
    white-space: nowrap;
}

.c-header__service-icon{
    background-color: var(--light-green);
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.c-header__service-arrow{
    margin-left: auto;
}

/*
HAMBURGER
*/

.c-header__hamburger{
    width: 2rem;
    display:none;
    z-index: 100;
    position: relative;
}

.c-header__hamburger:hover{
    cursor: pointer;
}

.c-header__hamburger-group{
    z-index: 1000;
}

.c-header__hamburger-patty:before{
    display:block;
    background-color: var(--white);
    height: 2px;
    border-radius: 0.25rem;
}

.u-header-home .c-header__hamburger-patty:before{
    background-color: var(--white);
}

.c-header__hamburger-patty:before,
.u-header-scrolled .c-header__hamburger-patty:before,
.u-header-scrolled.u-header-home .c-header__hamburger-patty:before{
    background-color: var(--black);
}

.c-header__hamburger-patty:first-of-type{
    margin-bottom: 0.5rem;
}

.c-header__hamburger-patty:first-of-type:before{
    content: '';
    width: 60%;
}

.c-header__hamburger-patty:nth-of-type(2):before{
    content: '';
    width: 80%;
    margin-bottom: 0.5rem;
}

.c-header__hamburger-patty:last-of-type:before{
    content: '';
    width: 100%;
}

.c-mob-nav{
    padding: 1rem;
    display:none;
    position:absolute;
    height: 100svh;
    width: calc(100svw - 2rem);
    color: var(--white);
    opacity: 0;
    transition: all 0.2s ease-out;
}

.c-mob-nav.u-nav-open{
    display:block;
    z-index: 500;
    animation: mobNavFade 0.5s normal forwards;
    transition: animation 0.5s ease-out;
}

.c-mob-nav-bg{
    display:none;
    position: absolute;
    z-index: 400;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: hsla(234,90%,11%,1);
    animation: closeMobNav 1s normal forwards;
    transition: all 1s ease-out;
}

.c-mob-nav-bg.u-nav-open{
    display:block;
    animation: enlargeMobNav 1s normal forwards;
    transition: all 1s ease-out;
}

.c-mob-nav__header{
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.c-mob-nav__header-close{
    position:relative;
    height: 2.5rem;
    width: 2.5rem;
}

.c-mob-nav__header-close_line{
    display:block;
    position: absolute;
    background-color: var(--white);
    height: 2px;
    width: 2rem;
    border-radius: 0.25rem;
    top: 50%;
    left: 50%;
    transform-origin: center;
}

.c-mob-nav__header-close_line:first-of-type{
    transform: translate(-50%, -50%) rotate(45deg);
}

.c-mob-nav__header-close_line:nth-of-type(2){
    transform: translate(-50%, -50%) rotate(-45deg);
}

.c-mob-nav__nav ul{
    list-style: none;
    padding: 0;
}

.c-mob-nav__nav ul li:last-of-type a{
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
}

.c-mob-nav__nav ul li a{
    /* border-top: 1px solid rgba(255, 255, 255, 0.5); */
    display:flex;
    align-items: center;
    gap: 2rem;
    padding: 1.5rem 0;
    font-size: 2rem;
    font-weight: 200;
    color: var(--white);
}

.c-mob-nav__nav ul li a svg{
    transition: all 0.5s ease-out;
}

.c-mob-nav__nav ul li a:active svg{
    transform: rotate(90deg);
    transition: all 0.5s ease-out;
}

.c-mob-nav__nav ul li a:hover svg{
    transform: rotate(30deg);
    transition: all 0.2s ease-out;
}

@keyframes mobNavFade {
    0%{
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes enlargeMobNav{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(100);
    }
}

@keyframes closeMobNav{
    0%{
        transform: scale(50);
    }
    100%{
        transform: scale(0);
    }
}

/*
UTILS 
*/

.u-header-home ul li a,
.u-header-home ul li span{
    color: var(--white);
}

.u-header-home ul li a:hover,
.u-header-home ul li span:hover{
    /* background-color: var(--main-purple); */
    background-color:rgb(49, 53, 90);
}

.u-header-home .c-btn-primary{
    border: 2px solid var(--white);
    color: var(--white);
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s ease-out;
}

.u-header-home .c-btn-primary:hover{
    background-color: var(--white);
    color: var(--black);
    transition: all 0.2s ease-out;
    cursor: pointer;
}

.u-header-scrolled.u-header-home .c-header ul li a,
.u-header-scrolled.u-header-home .c-header ul li span{
    color: var(--black);
}

.u-header-scrolled.u-header-home .c-header ul li a:hover,
.u-header-scrolled.u-header-home .c-header ul li span:hover{
    background-color: var(--light-green);
}


.u-header-scrolled.u-header-home .c-btn-primary{
    border: 2px solid var(--black);
    color: var(--black);
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s ease-out;
}

.u-header-scrolled.u-header-home .c-btn-primary:hover{
    background-color: var(--black);
    color: var(--white);
    transition: all 0.2s ease-out;
    cursor: pointer;
}

.u-services-open{
    display:block;
}

.u-header-scrolled{
    /* backdrop-filter: blur(2rem); */
    background-color: rgb(255, 255, 255);
    transition: all 0.2s ease-out;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    transition: all 0.2s ease-out;
}

.u-header-scrolled .c-header{
    padding: 0.5rem 0;
}

.u-header-scrolled .c-header__logo{
    height: 4rem;
    transition: all 0.2s ease-out;
}



/*
MEDIA QUERIES MOBILE NAV
*/

@media screen and (max-width:1240px) {
    .c-header__nav ul li{
        margin-right: 2rem;
    }
}

@media screen and (max-width:960px) {
    .c-header__logo{
        height: 4rem;
    }

   .c-header__hamburger{
        display: block;
   }
    
    .c-header__hamburger-group{
        display:flex;
        flex-direction: column;
    }

    .c-header__nav,
    .c-header .c-btn-primary{
        display:none;
    }
}

@media screen and (max-width:760px) {
    .c-header__logo{
        height: 3.75rem;
    }
}