.c-contact-cta{
    background-color: #000;
    color: var(--white);
    padding: 6rem 0;
}

.c-contact-cta h2{
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 2rem;
}

.c-contact-cta__row{
    display:flex;
    gap: 6rem;
}

.c-contact-cta__detail:first-of-type{
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.c-contact-cta__detail a{
    display:flex;
    gap: 1rem;
    color: var(--white);
}

.c-contact-cta-btn{
    border-radius: 0.5rem;
    font-weight: 600;
    border: 2px solid var(--main-green);
    background-color: transparent;
    color: var(--white);
    padding: 1rem 2rem;
    display: block;
    transition: all 0.2s ease-out;
}

.c-contact-cta-btn:hover{
    background-color: var(--main-green);
    color: var(--black);
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.c-contact-cta-fb{
    display:flex;
    gap: 2rem;
    align-items: center;
}

.c-contact-cta__success{
    font-size: 0.75rem;
    margin: 0;
}

/*
    FORM
*/
.c-contact-cta__form{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.c-contact-cta__form .c-fieldset:has(textarea){
    grid-column: 1 / span 3;
}

.c-contact-cta__form .c-fieldset:first-of-type{
    grid-column: 1 / span 2;
}




/*
MEDIA QUERIES
*/

@media screen and (max-width: 1240px){
    .c-contact-cta__row{
        gap: 4rem;
    }

    .c-contact-cta__form{
        grid-template-columns: 1fr;
    }

    .c-contact-cta__form .c-fieldset:has(textarea){
        grid-column: auto;
    }
    
    .c-contact-cta__form .c-fieldset:first-of-type{
        grid-column: auto;
    }
}

@media screen and (max-width: 760px){
    .c-contact-cta h2{
        font-size: 2rem;
    }

    .c-contact-cta__row{
        flex-direction: column;
    }

    .c-contact-cta-btn{
        width: fit-content;
    }
}
