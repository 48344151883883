footer{
    background-color: var(--white);
    padding: 6rem 0;
}

.c-footer__grid{
    display:grid;
    grid-column-gap: 2rem;
    grid-template-rows: auto;
    grid-template-columns: 3fr 1fr 1fr 1fr;
}

.c-footer__socials{
    display:flex;
    margin: 2rem 0;
    padding: 0;
    gap: 1rem;
    align-items: center;
    list-style: none;
}

.c-footer__title{
    margin-bottom: 1.5rem;
}

.c-footer__links{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    list-style: none;
}

.c-footer-logo{
    height: 4rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1240px) {
    .c-footer__grid>div:first-of-type{
        grid-area: credits;
    }

    .c-footer__grid>div:nth-of-type(2){
        grid-area: services;
    }

    .c-footer__grid>div:nth-of-type(3){
        grid-area: sitemap;
    }

    .c-footer__grid>div:last-of-type{
        grid-area: details;
    }

    .c-footer__grid{
        gap:2rem;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'services sitemap'
                             'details credits';
    }
}

@media screen and (max-width: 760px) {
    .c-footer__grid>div:first-of-type{
        grid-column: 1 / span 2;
    }

    .c-footer__grid{
        gap:2rem;
        grid-template-columns: 1fr;
        grid-template-areas: 'services'
                             'sitemap'
                             'details' 
                             'credits';
    }
}