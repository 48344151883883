.c-fieldset{
    padding: 0;
    display: inline-block;
}

.c-fieldset label{
    display:block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
}

.c-fieldset input,
.c-fieldset textarea{
    width: calc(100% - 2rem);
    transition: border 0.1s ease-out;
    border: 2px solid #ebebeb;
    padding: 1rem;
    border-radius: 0.5rem;
    outline: none;
}

.c-fieldset input:focus,
.c-fieldset textarea:focus{
    background-color: var(--white);
    border: 2px solid var(--main-purple);
    transition: border 0.1s ease-out;
}

.c-fieldset input:valid,
.c-fieldset textarea:valid{
    background-color: var(--white) !important;
    border: 2px solid var(--main-green);
}

/* .c-fieldset input:invalid,
.c-fieldset textarea:invalid{
    border: 1px solid var(--bs-danger);
} */
