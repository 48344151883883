@import url('fonts.css');

:root{
  /* --bg-color: #F8F8F8;
  --main-blue: #0D2934;
  --secondary-blue: #03161d;
  --light-blue: rgb(165, 222, 245, 0.3);
  --main-green: #15D855;
  --light-green: #BEFFD0;
  --main-beige: #F6F4EE; */
  --bg-color: #F4FFFA;
  --main-green: #00EF8B;
  --light-green: #D7FDED;
  --main-purple: #999EF3;
  --hover-purple: rgba(153, 158, 243, 0.5);
  --white: #FFFFFF;
  --gray: #e0e0e0;
  --black: #161D18;
}

html,body {
  margin: 0;
  scroll-behavior: smooth;
  color: var(--black);
  background-color: var(--bg-color);
  font-family:  'Epilogue', 'Segoe UI', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
}

h1{
  font-size: 3.5rem;
  line-height: 1.5;
  font-weight: 600;
}

a{
  text-decoration: none;
  color: var(--black);
}

textarea{
  resize: vertical;
  font-family: Montserrat;
}

p{
  line-height: 1.5;
}

section:first-of-type{
  padding-top: 8rem;
}

/* Container */
.u-container{
  max-width: 80vw;
  margin: 0 auto;
}

.u-marg-y{
  margin-top: 6rem;
  margin-bottom: 6rem;
}

@media screen and (max-width: 760px) {
    .u-container{
      max-width: 90vw;
    }
}

