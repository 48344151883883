.c-contact{
    display:flex;
    align-items:center;
    justify-content:space-around;
    min-height: 86vh;
}

.c-contact__content{
    width: 50%;
    background-color: var(--white);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 2rem;
}

.c-contact .c-fieldset input,
.c-contact .c-fieldset textarea{
    background-color: #fcfcfc;
}

.c-contact__form{
    margin-top:1rem;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.c-contact__persona{
    display:flex;
    align-items: center;  
    gap:1rem;  
    margin-bottom: 1.5rem;
}

.c-contact__persona-text p{
    margin:0;
    font-size: 0.75rem;
    color: #979797;
}

.c-contact__persona-img{
    width:5rem;
}

div.c-contact-details{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0 2rem 0;
}

.c-contact-detail a{
    display:flex;
    gap: 1rem;
    align-items: center;
    color: var(--black);
}

.c-contact-btn{
    border-radius: 0.5rem;
    font-weight: 600;
    border: 2px solid var(--main-green);
    background-color: var(--main-green);
    color: var(--white);
    padding: 1rem 2rem;
    display: block;
    transition: all 0.2s ease-out;
}

.c-contact-btn:hover{
    color: var(--black);
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.c-contact__success{
    font-size: 0.75rem;
    color: #979797;
    margin:0;
}


/*
UTILS
*/

.u-contact-bg{
    background-image:
    radial-gradient(at 0% 90%, hsla(154,100%,46%,1) 0px, transparent 50%);
    /* radial-gradient(at 0% 0%, hsla(154,100%,46%,1) 0px, transparent 50%); */
}

.u-grid-span-1-3{
    grid-column: 3 / span 1;
  }
  
  .u-grid-span-2-3{
    grid-column: 1 / span 2;
  }

.u-grid-span-3-3{
    grid-column: 1 /span 3;
}

@media screen and (max-width: 1240px){
    .c-contact{
        flex-direction: column;
        justify-content: normal;
        gap: 2rem;
    }

    .c-contact__content{
        box-sizing: border-box;
        width: 60%;
    }
}

@media screen and (max-width: 960px){
    .c-contact h1{
        font-size: 3rem;
    }

    .c-contact__content{
        width: 100%;
    }
}

@media screen and (max-width: 760px){
    .c-contact{
        align-items: normal;
        gap:1rem;
    }

    .c-contact h1{
        font-size: 2rem;
    }

    .u-contact-bg{
        padding-bottom: 4rem;
    }

    .c-contact__form{
        display:flex;
        flex-direction: column;
    }

    .c-contact__content{
        padding: 1rem;
    }

    .u-grid-span-1-3{
        grid-column: 1 / span 2;
      }
      
      .u-grid-span-2-3{
        grid-column: 1 / span 2;
      }
}