/* Open Sans Light */
@font-face {
  font-family: 'EpilogueLight';
  src: url('assets/fonts/Epilogue-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

/* Open Sans Regular */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* Open Sans Medium */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/* Open Sans SemiBold */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

/* Open Sans Bold */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

/* Open Sans ExtraBold */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

/* Open Sans Light Italic */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

/* Open Sans Italic */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

/* Open Sans Medium Italic */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

/* Open Sans SemiBold Italic */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

/* Open Sans Bold Italic */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

/* Open Sans ExtraBold Italic */
@font-face {
  font-family: 'Epilogue';
  src: url('assets/fonts/Epilogue-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}
