.c-service__intro{
    display:flex;
}

.c-service__intro h2{
    color: #b1afaf;
    font-weight: 200;
    font-size: 1.25rem;
}

.c-service__intro h1{
    font-size: 2.5rem;
    font-weight: 500;
}

.c-service__intro-visuals{
    width: 50%;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.c-service__intro-visuals img{
    border-radius: 0.5rem;
}

.c-service__intro-visuals_big{
    grid-column: 1 / span 3;
    width: 100%;
    object-fit: cover;
}

.c-service__options{
    margin: 4rem 0;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;    
    gap: 1rem;
}

.c-service__options-card{
    position: sticky;
    background-color: var(--light-green);
    padding: 1rem;
    border-radius: 0.5rem;
    width: 22rem;
}

.c-service__options-card.u-is-hot::before{
    content: 'Populair';
    font-size: 0.8rem;
    position: absolute;
    top: -1.7rem;
    background-color: var(--main-green);
    padding: 0.4rem;
    border-radius: 0.5rem 0.5rem 0 0;
}

.c-card-divider{
    border: 0.5px solid var(--hover-purple);
}

/*
UTILS
*/

.u-is-hot{
    border: 3px solid var(--main-green);
}