section.c-hero{
    padding: 0;
    min-height: 100vh;
    color: var(--white);
    background-color:hsla(234,90%,11%,1);
background-image:
radial-gradient(at 84% 97%, hsla(242,100%,70%,1) 0px, transparent 50%),
radial-gradient(at 0% 0%, hsla(154,100%,46%,1) 0px, transparent 50%);
display:flex;
justify-content:center;
align-items: center;
}

.c-hero-grid{
    display:flex;
    align-items:center;
}

.c-hero-grid>div{
    width: 60%;
}

.c-hero-grid>div>p{
    margin: 1rem 0 2rem 0;
}

.c-hero h1{
    font-size: 8rem;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 1rem;
}

.c-hero-cta{
    background-color: var(--white);
    color: var(--black);
    align-items:center;
    display: inline-block;
    border-radius: 1.25rem;
    padding: 0.5rem 1rem;
}

.c-hero-cta>div{
    display:flex;
    justify-content: center;
    align-items: center;
}

.c-hero-cta svg{
    transition: all 0.2s ease-out;
}

.c-hero-cta:hover svg{
    transform: translate(2px, -2px);
    transition: all 0.2s ease-out;
}

.c-hero__visual{
    height: 40rem;
    clip-path: url(#clipPath);
}

.c-hero__visual img{
    object-fit: cover;
}

.c-home-cta{
    background-color: var(--white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    display:flex;
}

.c-home-cta__content{
    padding: 3rem;
}



.c-home-services h2{
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: center;
}

.c-home-services__carousel{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

.c-home-services__carousel-card{
    position: relative;
    width: 100%;
    height: 30rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}


.c-home-services__carousel-card img{
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.c-home-services__carousel-card:hover img{
    overflow: hidden;
    transform: scale(1.05);
    transition: all 0.2s ease-out;
}

.c-home-services__carousel-card_note{
    box-sizing: border-box;
    padding: 2rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(0.5rem);
    border-radius: 0 0 0.5rem 0.5rem;
    font-size: 1.5rem;
    color: var(--white);
    display: grid;
    align-items: center;
    grid-template-columns: 70% 30%;
}

.c-home-services__carousel-card_note p{
    margin: 0;
}

.c-home-services__carousel-card_note a{
    margin-left: auto;
    height: 4rem;
    width: 4rem;
    background-color: var(--main-green);
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
}




#discover{
    scroll-margin-top: 10rem;
}

@media screen and (max-width: 1400px){
    .c-home-services__carousel-card{
        height: 25rem;
    }
}

@media screen and (max-width:1240px){
    .c-hero-grid{
        display:flex;
    }

    .c-home-services__carousel-card{
        height: 22rem;
    }

    .c-home-services__carousel-card_note{
        padding: 1rem;
        font-size: 1.25rem;
    }

    .c-home-services__carousel-card_note a{
        height: 3rem;
        width: 3rem;
    }

    .c-home-services__carousel-card_note a svg{
        width: 1.5rem;
        height: 1.5rem;
    }
}

@media screen and (max-width: 960px) {
    .c-home-services__carousel{
        display:flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 840px){
    .c-hero h1{
        font-size: 5rem;
    }

    .c-hero-grid>div{
        width: 100%;
    }
}

@media screen and (max-width:760px) {
    .c-home-services h2{
        font-size: 2rem;
        text-align: left;
    }

    .c-home-services__carousel-card{
        height: 18rem;
    }

}