.c-btn-primary{
    white-space: nowrap;
    border: 2px solid var(--black);
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s ease-out;
}

.c-btn-primary:hover{
    background-color: var(--black);
    color: var(--white);
    transition: all 0.2s ease-out;
    cursor: pointer;
}